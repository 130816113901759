import React from "react"
import {Link} from "gatsby"
import logo_cmm from "../../images/logo-cmm.svg"
import "./styles.css"
import resourceCard1 from "../../images/3.3/PB_icon-new.png"
import resourceCard2 from "../../images/3.3/VB_icon-new.png"
import resourceCard3 from "../../images/3.3/BR_icon.png"
import hcpPatientBrochure from '../../pdfs/US-VAS-03467_ HCP_BlinkRx_Patient_Brochure_Oct_21.pdf'

export default props => {
  const supStyle = {
    top: '-1em'
  };


  function goModal(evt) {
    const dataAtt = evt.target.dataset.modal;
    //console.log(dataAtt)
    props.goModal(dataAtt);
  };

  function goModalTwo(evt) {
    const dataAtt = evt.target.dataset.modal;
    const dataHref = evt.target.dataset.href || null;
    props.goModalTwo(dataAtt, dataHref);
  };

  function gotosite() {
    window.location = document.getElementById("menu").value; // JQuery:  $("#menu").val();
  }

  return (
    <main className="center-grid width-1200 mobile-m-b-20">
      


        <section name="section3">
          <div className="resourceCard-container">

             <div className="resourceCard pb">
                <img src={resourceCard1} alt="Patient brochure thumbnail"></img>
                <div className="context">
                <h3>Patient brochure</h3>
                <p>Patients can learn more about the benefits of VASCEPA as well as coverage and savings options before they start their treatment</p>
                <h4>Download the brochure</h4>
                <div>

                  <div class="select-box">
                    <div class="chevron"></div>
                    <label className=""><b>Select language</b></label>
                    <ul>
                      <li><a href="https://amarincorp.com/docs/VAS-02344VascepaHCCPatientBrochure_CVLaunch_R1.pdf"  target="_blank" className="select-option">English</a></li>
                    </ul>
                  </div>
                </div>
                </div>
              </div>

             <div className="resourceCard vb">
                <img src={resourceCard2} alt="Getting VASCEPA brochure thumbnail"></img>
                <div className="context">
                <h3>Getting VASCEPA brochure</h3>
                <p>A prescription companion brochure to help patients ensure that they get the branded VASCEPA you prescribed at their local pharmacy</p>
                <h4>Download the brochure</h4>
                <div>
                  <div class="select-box">
                      <div class="chevron"></div>
                      <label><b>Select language</b></label>
                      <ul>
                        <li><a href="https://amarincorp.com/docs/Protect_Rx.pdf"  target="_blank" className="select-option">English</a></li>
                      </ul>
                    </div>
                </div>
                </div>
              </div>

              <div className="resourceCard pb">
                <img src={resourceCard3} alt="BlinkRx Brochure thumbnail"></img>
                <div className="context">
                <h3>BlinkRx Brochure</h3>
                <p>Brochure for patients to explain BlinkRx offerings and how BlinkRx seeks to find the lowest price available on VASCEPA</p>
                <h4>Download the brochure</h4>
                <div>

                  <div class="select-box">
                    <div class="chevron"></div>
                    <label><b>Select language</b></label>
                    <ul>
                      <li><a href="https://amarincorp.com/docs/US-VAS-03467BlinkRxPatientBrochure_English.pdf"  target="_blank" className="select-option">English</a></li>
                      <li><a href="https://amarincorp.com/docs/US-VAS-03523BlinkRxPatientBrochure_SimplifiedChinese.pdf" target="_blank" className="select-option">Simplified Chinese</a></li>
                      <li><a href="https://amarincorp.com/docs/US-VAS-03522BlinkRxPatientBrochure_Spanish.pdf" target="_blank" className="select-option">Spanish</a></li>
                      <li><a href="https://amarincorp.com/docs/US-VAS-03524BlinkRxPatientBrochure_TraditionalChinese.pdf" target="_blank" className="select-option">Traditional Chinese</a></li>
                    </ul>
                  </div>
                </div>
                </div>
              </div>            
          </div>
        </section>
    </main>
  )
}
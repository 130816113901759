import React, { Component } from "react"
import HeroImage from '../components/3.4_patient_resources/HeroImage'
import Main from '../components/3.4_patient_resources/Main'
import Header from "../components/header/Header"
import ISI from '../components/isi/ISI'
import Footer from '../components/footer/Footer'
import Modal from '../components/modal/Modal'
import HomeModal from '../components/modal/HomeModal'

import SEO from "../components/seo"

import Loader from "../components/loader"


export default class GVNow extends Component {

  constructor(props) {
    super(props)
    this.state = {
       stickyNav: false,
       isMobile: false,
       modal:  null,
       href: null
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    this.setState({ isMobile: bool })
  }

  handleClick(e) {
    // console.log('clicked');
  }

  goModal = (modal,href) => {
    this.setState(() => ({ modal: modal, href:href }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null, href:null }))
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {

    return (
      <>
      <Loader>
      <SEO
        title="VASCEPA Patient Resources"
        keywords={[""]}
        description="Help patients learn more about the benefits of VASCEPA as well as coverage and savings options before they start their treatment."/>
        <HomeModal/>
        <Modal modal={this.state.modal} href={this.state.href} modalClosed={this.modalClosed}/>
        <Header isMobile={this.state.isMobile} hideNav={this.state.hideNav} goModal={this.goModal}/>
        <div className="grad"></div>
        <HeroImage onClick={this.handleClick} bckgrdImage={'header3-4'}/>
        <div className="wrapper">
            <Main isMobile={this.state.isMobile} goModal={this.goModal}/>
        </div>
        <ISI scrollPos={this.state.unstick} disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
        <div className="ref-outer">
        {/* <div className="footnotes-container">
          <div className="footnotes">
            <p id="terms" className="cv-footnote hang">
              *Offer Restrictions: May not be used to obtain prescription drugs paid in part by Federal or State Programs including Medicare, Medicaid, Medicare Advantage, Medicare Part D, Tricare, VA. Most eligible, insured patients will pay as little as $9 of their copay for either each month or a 90 day fill, with a maximum savings of up to $150 per month or $450 on a 90 day fill. Not for use by residents of MA or VT, or medical professionals licensed in those states. This offer is not valid for those patients under 18 years of age or patients whose plans do not permit use of a copay card. Void where prohibited by law, taxed, or restricted. Eligible patients include those who participate in commercial insurance, through a healthcare exchange, or pay cash. Offer good through December 31, 2021.
            </p>
            <p className="cv-footnote hang"><sup>†</sup>Universal Pharmacy Card (UPC) may be applied for any eligible patient by entering all 4 codes into the notes section of an e-prescription.</p>
          </div>
        </div> */}
        </div>
        <Footer />
        </Loader>
      </>
    )
  }
}
